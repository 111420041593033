import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

function attachPhoneFieldScript() {
    const inputs = document.querySelectorAll('input.phone-field');

    inputs.forEach((input) => {
        applyIntlPhoneScript(input as HTMLInputElement);

        input.addEventListener('focus', function applyScriptOnFocus(event) {
            if (event.target) {
                applyIntlPhoneScript(event.target as HTMLInputElement);
            }
        });

        input.addEventListener('blur', function applyScriptOnBlur(event) {
            if (event.target) {
                applyIntlPhoneScript(event.target as HTMLInputElement);
            }
        });
    });
}

function applyIntlPhoneScript(input: HTMLInputElement) {
    let instance = intlTelInput.getInstance(input);

    if (!instance) {
        instance = intlTelInput(input, {
            utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js',
            nationalMode: !input.value.includes('+1') || input.value.includes('+1'),
            allowDropdown: false,
            showFlags: false,
            formatAsYouType: true,
            formatOnDisplay: true,
            initialCountry: 'us',
            strictMode: true,
        });
    }

    instance.handleUtils();
}

document.addEventListener('DOMContentLoaded', attachPhoneFieldScript);
document.addEventListener('livewire:load', attachPhoneFieldScript);

if (window.Livewire) {
    window.Livewire.hook('element.initialized', attachPhoneFieldScript);
    window.Livewire.hook('element.updated', attachPhoneFieldScript);
}
