import Alpine from 'alpinejs';
import type { SearchClient, SearchIndex } from 'algoliasearch/lite';
import algoliasearch from 'algoliasearch/lite';
import type { Hit, SearchResponse } from '@algolia/client-search';
import type { ParsedQs } from 'qs';
import qs from 'qs';

type ShownValueResult = {
    label: string;
    value: string;
};

type RefinementInputValue = {
    value: string;
    icon?: string;
};

type Props = {
    algoliaId: string;
    algoliaKey: string;
    indexName: string;
    showResultsOnStart: boolean;
    showFilters: boolean;
    showMainSearchField: boolean;
    showRefinementInputListFilters: boolean;
    showRefinementInputCheckboxFilters: boolean;
    showMemberDirectorySearchInputs: boolean;
    dataTypeFilters: Array<string>;
    hitsPerPage: number;
    hitsTotal: number;
    defaultInfoText: string;
    gridView: boolean;
    defaultLogoUrl: string;
    refinementInputs: Record<string, Array<string>>;
    refinementFilters: Record<string, string>;
    listRefinementInputs: Array<{ name: string; label: string; values: Array<RefinementInputValue> }>;
    checkboxRefinementInputs: Array<{ name: string; label: string; values: Array<RefinementInputValue> }>;
    searchInputs: Record<string, string>;
    memberDirectory?: {
        shownValuesUsingBadge: Array<ShownValueResult>;
        shownValues: Array<ShownValueResult>;
        shownMemberships: Array<ShownValueResult>;
        searchTextInputs: Array<ShownValueResult>;
        showSearchInputs: boolean;
        resultsLayout: App.Context.Search.InstaSearch.Enums.MemberDirectoryResultsLayout;
    };
    browserHistory: boolean;
    layout: App.Context.Search.InstaSearch.Enums.InstaSearchLayout;
};

Alpine.data(
    'instaSearch',
    ({
        showResultsOnStart = false,
        showMainSearchField = false,
        dataTypeFilters = [],
        defaultInfoText = '',
        gridView = false,
        defaultLogoUrl = '',
        browserHistory = false,
        hitsTotal = 0,
        ...props
    }: Props) => ({
        showResultsOnStart,
        showMainSearchField,
        dataTypeFilters,
        defaultInfoText,
        gridView,
        defaultLogoUrl,
        browserHistory,
        hitsTotal,
        ...props,

        index: {} as SearchIndex,
        client: {} as SearchClient,
        query: {
            searchInputs: {} as Record<string, string>,
            refinementInputs: {} as Record<string, Array<string>>,
        },
        searchInputs: {} as Record<string, string>,
        refinementListsValues: {} as Record<string, object>,
        refinementInputValuesForPill: [] as Array<object>,
        filters: '',
        facetFilters: [],
        facetsCount: {} as Record<string, Record<string, number>>,
        results: [] as Array<SearchResponse<any>>,
        currentPage: 0,
        isLastPage: false,
        showMobileFilter: false,
        searchFinished: false,
        init() {
            this.client = algoliasearch(this.algoliaId, this.algoliaKey);
            this.index = this.client.initIndex(this.indexName);

            const parsedQs = qs.parse(window.location.search.substr(1));
            this.searchInputs =
                ((parsedQs.query as ParsedQs)?.searchInputs as Record<string, string>) || props.searchInputs || {};
            this.refinementInputs =
                ((parsedQs.query as ParsedQs)?.refinementInputs as Record<string, Array<string>>) ||
                props.refinementInputs ||
                {};
            this.refinementInputs = props.refinementInputs || {};
            this.runSearchIfInputsAreGiven();
            this.setRefinementListItemValuesForPills();

            if (this.showResultsOnStart) {
                this.search(true);
            } else {
                this.loadFilterCount();
            }

            this.loadFilterCount();
        },
        loadRefinementListValues(attribute: string) {
            this.index.searchForFacetValues(attribute, '*').then(({ facetHits }) => {
                this.refinementListsValues[attribute] = facetHits;
            });
        },
        initRefinementListItem(el: HTMLInputElement, list: string, value: string) {
            if (this.refinementInputs[list]?.includes(value)) {
                el.checked = true;
            }
        },
        updateRefinementListItem(checked: boolean, value: string, attribute: string, className: string) {
            // Update mobile and desktop menu
            const htmlItems = document.getElementsByClassName(className) as HTMLCollectionOf<HTMLInputElement>;
            for (let i = 0; i < htmlItems.length; i++) {
                htmlItems[i].checked = checked;
            }

            if (checked) {
                if (!this.refinementInputs[attribute]) {
                    this.refinementInputs[attribute] = [];
                    this.refinementInputs[attribute].push(value);
                } else {
                    this.refinementInputs[attribute].push(value);
                }
            } else {
                this.refinementInputs[attribute].splice(this.refinementInputs[attribute].indexOf(value), 1);

                if (this.refinementInputs[attribute].length === 0) {
                    delete this.refinementInputs[attribute];
                }
            }
            this.setRefinementListItemValuesForPills();
            this.runSearchIfInputsAreGiven();
        },
        setRefinementListItemValuesForPills() {
            // Get an array of all refinement list values and keys to loop over them in the template
            this.refinementInputValuesForPill = Object.keys(this.refinementInputs).reduce(
                (acc, key) => {
                    return acc.concat(this.refinementInputs[key].map((value) => ({ key, value })));
                },
                [] as Array<{ key: string; value: string }>,
            );
        },
        updateRefinementListItemFromPill(value: string, attribute: string) {
            // Find input and uncheck it
            const inputs = document.getElementsByClassName(
                encodeURIComponent(attribute + '_' + value),
            ) as HTMLCollectionOf<HTMLInputElement>;

            // set checked to false for all inputs
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].checked = false;
            }

            // Remove value from refinementInputs
            this.refinementInputs[attribute].splice(this.refinementInputs[attribute].indexOf(value), 1);
            if (this.refinementInputs[attribute].length === 0) {
                delete this.refinementInputs[attribute];
            }

            this.setRefinementListItemValuesForPills();
        },
        howManyRefinementListItemsSelected(attribute: string) {
            if (this.refinementInputs[attribute] === undefined) {
                return 'Choose one or multiple';
            }

            if (this.refinementInputs[attribute].length === 1) {
                return this.refinementInputs[attribute][0];
            }

            return this.refinementInputs[attribute].length + ' items selected';
        },
        search(initialSearch = false) {
            const queries = this.generateQueriesForGivenInputs();

            this.client.search(queries).then(({ results }) => {
                // Update facets count only for text search inputs or initial search
                if (initialSearch || Object.keys(this.searchInputs).length > 0) {
                    this.facetsCount = results[0].facets ?? {};
                }

                this.currentPage = results[0].page;
                this.isLastPage = results[0].nbPages === results[0].page + 1;
                this.hitsTotal = results[0].nbHits;
                this.results = this.getResultsGivenInAllQueries(results);
                this.searchFinished = true;
            });

            this.writeQueryStringIfGiven();
        },
        loadMoreResults() {
            const queries = this.generateQueriesForGivenInputs(this.currentPage + 1);

            this.client.search(queries).then(({ results }) => {
                this.currentPage = results[0].page;
                this.isLastPage = results[0].nbPages === results[0].page + 1;
                // Add results to existing results
                this.results = this.results.concat(this.getResultsGivenInAllQueries(results));

                this.writeQueryStringIfGiven();
            });
        },
        loadFilterCount() {
            const facetFilters = [
                this.dataTypeFilters.map((dataTypeFilter) => {
                    return `DataType:${dataTypeFilter}`;
                }),
            ];

            const queries = [
                {
                    indexName: this.indexName,
                    query: '',
                    facetFilters: facetFilters,
                    facets: ['*'],
                    hitsPerPage: 1,
                },
            ];

            this.client.search(queries).then(({ results }) => {
                this.facetsCount = results[0].facets ?? {};
            });
        },
        runSearchIfInputsAreGiven() {
            // Remove empty search inputs that remain after clearing an input
            for (const [key, value] of Object.entries(this.searchInputs)) {
                if (value.length === 0) {
                    delete this.searchInputs[key];
                }
            }

            if (Object.keys(this.searchInputs).length > 0 || Object.keys(this.refinementInputs).length > 0) {
                this.search();
            } else {
                this.resetInputs();
                this.loadFilterCount();
            }
        },
        writeQueryStringIfGiven() {
            if (!this.browserHistory) {
                return;
            }

            const queryString = {
                query: {
                    searchInputs: {} as Record<string, string>,
                    refinementInputs: {} as Record<string, Array<string>>,
                    page: this.currentPage > 0 ? this.currentPage : undefined,
                },
            };

            for (const [key, value] of Object.entries(this.searchInputs)) {
                if (value.length) {
                    queryString.query.searchInputs[key] = value;
                }
            }

            for (const [key, value] of Object.entries(this.refinementInputs)) {
                queryString.query.refinementInputs[key] = value;
            }

            history.pushState(null, '', '?' + qs.stringify(queryString));
        },
        generateQueriesForGivenInputs: function (pageToLoad = 0) {
            // Remove empty search inputs that remain after clearing an input
            for (const [key, value] of Object.entries(this.searchInputs)) {
                if (!value.length) {
                    delete this.searchInputs[key];
                }
            }

            const queries = [];

            const isTimeRangeValue = function (value: string): boolean {
                if (value.charAt(0) == ':') {
                    return true;
                }

                return false;
            };

            const isTimestampValue = function (value: string) {
                const charactersToLookFor = ['<', '>'];

                for (let i = 0; i < charactersToLookFor.length; i++) {
                    if (value.charAt(0) == charactersToLookFor[i]) {
                        return true;
                    }
                }

                return false;
            };

            const facetFilters = Object.keys(this.refinementInputs).map((attribute) => {
                return this.refinementInputs[attribute].map((value) => {
                    return `${attribute}:${value}`;
                });
            });

            const filters = Object.keys(this.refinementFilters)
                .map((filterName) => {
                    const value = this.refinementFilters[filterName];

                    if (isTimeRangeValue(value)) {
                        return `${filterName}${value}`;
                    }

                    if (isTimestampValue(value)) {
                        return `${filterName} ${value}`;
                    }

                    return `${filterName}:${value}`;
                })
                .join(' AND ');

            // Add all given data types to the facet filters if no data type is given
            if (!facetFilters.some((facetFilter) => facetFilter.some((filter) => filter.includes('DataType')))) {
                const dataTypeFilters = this.dataTypeFilters.map((dataTypeFilter) => {
                    return `DataType:${dataTypeFilter}`;
                });

                facetFilters.push(dataTypeFilters);
            }

            for (const searchInputKey in this.searchInputs) {
                queries.push({
                    indexName: this.indexName,
                    query: this.searchInputs[searchInputKey],
                    facetFilters: facetFilters,
                    filters: filters,
                    // restrictSearchableAttributes: searchInputKey,
                    facets: ['*'],
                    page: pageToLoad,
                    hitsPerPage: this.hitsPerPage,
                });
            }

            if (Object.keys(this.searchInputs).length === 0) {
                queries.push({
                    indexName: this.indexName,
                    query: '',
                    facetFilters: facetFilters,
                    facets: ['*'],
                    filters: filters,
                    page: pageToLoad,
                    hitsPerPage: this.hitsPerPage,
                });
            }

            return queries;
        },
        getResultsGivenInAllQueries: function (results: Array<SearchResponse<any>>) {
            return results[0].hits.filter(function (obj: Hit<any>) {
                return results.every(function (a) {
                    return a.hits.some(function (b) {
                        return b.objectID === obj.objectID;
                    });
                });
            });
        },
        areAnyInputsFilled: function () {
            return Object.keys(this.searchInputs).length > 0 || Object.keys(this.refinementInputs).length > 0;
        },
        resetInputs: function () {
            const checkboxes = this.$root.querySelectorAll('input[type="checkbox"]') as NodeListOf<HTMLInputElement>;

            checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
            });

            this.searchInputs = {};
            this.refinementInputs = {};
            this.results = [];
            this.refinementInputValuesForPill = [];
            this.writeQueryStringIfGiven();

            if (this.showResultsOnStart) {
                this.search();
            }
        },
        getDataTypeFilterCount: function (facetFilter: string, key: string) {
            if (!this.facetsCount[facetFilter] || this.facetsCount[facetFilter][key] === undefined) return 0;

            return this.facetsCount[facetFilter][key];
        },
        getDataTypeFilterCountText: function (facetName: string, filterName: string) {
            return `(${this.getDataTypeFilterCount(facetName, filterName)})`;
        },
        getDataTypeFilterHasMatches: function (facetName: string, filterName: string) {
            return this.getDataTypeFilterCount(facetName, filterName) > 0;
        },
    }),
);
