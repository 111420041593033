import '../../css/front/app.css';

import './app/sentry/sentry';
import './app/intercom/intercom';
import 'alpinejs';
// @ts-expect-error Todo
import collapse from '@alpinejs/collapse';
// @ts-expect-error Todo
import focus from '@alpinejs/focus';
// @ts-expect-error Todo
import Truncate from '@alpine-collective/toolkit-truncate';

import type Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import './app/auth0/auth0';
import './app/cart/cart';
import './app/catalog/catalog';
import './app/search/search';
import './app/stripe/stripe';
import './app/swiper';

import './app/navigation/mobile';
import './app/navigation/navigation';
import './app/search/search';
import './app/customForms/customForms';

// Alpine components
import Alpine from 'alpinejs';
import './app/agenda/agenda';
import './app/bundle/bundle';
import './app/links/links';
import './app/placesAutocomplete/placesAutocomplete';
import './app/stripe/stripe';
import './app/video/video-stream';
import './app/video/video-on-demand';
import './app/video/zoom';
import './app/video/zoom-full-screen';
import './app/video/checkIns';
import './app/video/countdown';
import './app/eLearning/daily';
import './app/eLearning/stream';
import './app/inputs/repeater';
import './app/inputs/select';
import './app/inputs/phone';
import FormsAlpinePlugin from '../../../public/vendor/filament/module.esm';
import './app/instaSearch/instaSearch';
import './app/instaSearch/instaSearchField';

import type Component from '../../../vendor/livewire/livewire/js/component/index';
import type { ZoomMtg } from 'front/types/zoom';

window.Alpine = Alpine;

// @ts-expect-error Todo
Alpine.plugin(Truncate);
// @ts-expect-error Todo
Alpine.plugin(FormsAlpinePlugin);
// @ts-expect-error Todo
Alpine.plugin(collapse);
// @ts-expect-error Todo
Alpine.plugin(focus);

Alpine.start();

window.Pusher = Pusher;

declare global {
    interface Window {
        Sail: App.Http.Front.Resources.Settings.SettingsData;
        Stripe?: stripe.Stripe;
        Alpine: typeof Alpine;
        Pusher: typeof Pusher;
        Echo: Echo;
        Intercom: Function;
        ZoomMtg: typeof ZoomMtg | undefined;
        Livewire: {
            hook: ((hook: 'component.initialized', callback: (component: Component) => void) => void) &
                ((
                    hook: 'element.initialized' | 'element.updated' | 'element.removed',
                    callback: (el: HTMLElement, component: Component) => void,
                ) => void);
        };
        /**
         * Global function defined inside the "base.blade.php" file.
         */
        initGoogleMap: () => void;
    }
}
